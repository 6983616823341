import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { detailsField } from '../../services/employeeFieldType';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import './addCandidate.css'
import ButtonWrapper from '../../widgets/button/button';
import FileUploader from '../../widgets/fileUploader/fileUploader';
import { addCandidate, searchEmployeeByLetter, uploadLeaveAttachment } from '../../api-services/employee-services';
import { randomId } from '../../util_service/randomId/randomId';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';

interface AddCandidateProps {
    onClose: () => void,
    data?: any,
    isEditable?: boolean,
    handleNewCandidate: () => void
}

interface Option {
    name: string;
}

interface ReferralProps {
    name: string,
    employee_id: string,
    referralComment: string,
}

interface fieldValueProps {
    name: string,
    contactNo: string,
    emailId: string,
    resume: string,
    referral: any,
    id: number
}

interface fieldItem {
    name: string,
    label: string,
    type: string
}

const AddCandidate = (props: AddCandidateProps) => {
    const snackbarService = SnackbarService()
    const field = detailsField().newCandidateField
    const [fieldValue, setFieldValue] = useState<fieldValueProps>({
        name: '',
        contactNo: '',
        emailId: '',
        resume: '',
        referral: {
            name: '', employee_id: '', referralComment: '',
        },
        id: 0
    });
    const [file, setFile] = useState<File>()
    const [isReffered, setIsReffered] = useState<boolean>(false)
    const { control, handleSubmit, setValue, register } = useForm();
    const [loader, setLoader] = useState<boolean>(false);
    const [optionData, setOptionData] = useState<any[]>([]);

    console.log(fieldValue, "FieldValueFieldValueFieldValue")

    useEffect(() => {
        if (props.data && props.isEditable) {
            if (props.data.referral && Array.isArray(props.data.referral) && props.data.referral.length > 0) {
                props.data.referral = props.data.referral[0];
            }
            setFieldValue(props.data)
        }

        if (props.data.referral !== '' && props.isEditable) {
            setIsReffered(props.isEditable)
        }
    }, [props.data, props.isEditable])

    const handleField = (e: any) => {
        const { name, value } = e.target
        setFieldValue((preav: any) => {
            return { ...preav, [name]: value }
        })
        setValue(name, value)
    }

    const handleReferalCommentField = (e: any) => {
        const { name, value } = e.target
        setFieldValue((preav: any) => (
            {
                ...preav,
                referral: {
                    ...preav.referral,
                    [name]: value
                }
            }
        ))
    }

    const handleFileUpload = (file: File) => {
        setFieldValue((preav: any) => {
            return { ...preav, 'resume': file.name }
        })
        setValue('resume', file.name)
        setFile(file)
    }

    const onChecked = (e: any) => {
        console.log(e.target.checked, "Event")
        setIsReffered(e.target.checked)
    }


    const onSubmit = (data: any) => {

        console.log(file, 'Fileeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
        props.onClose()
        const id = randomId()
        fieldValue['id'] = id
        console.log(fieldValue)
        if (!props.isEditable) {
            addCandidate(fieldValue).then((response: any) => {
                console.log(response)
                if (response.data.success) {
                    uploadLeaveAttachment(id, file).then((response: any) => {
                        if (response !== null && response.data.success) {
                            snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })

                        } else {
                            snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
                        }
                    })
                    props.handleNewCandidate()
                }
            })
        } else {
            console.log('Edit Api called')
        }

    }

    console.log(fieldValue, 'Field Value')

    const handleAutocompleteChange = (e: any, newValue: ReferralProps[], field: any) => {
        console.log(newValue, "newValue")
        console.log(field, "field")
        setFieldValue((preav: any) => ({
            ...preav,
            [field]: newValue
        }))
        setValue(field, newValue)

    }

    const searchApiCall = async (newValue: any, flag?: boolean) => {
        const result = newValue
        let val;



        try {
            const response: any = await searchEmployeeByLetter(result);

            if (response && response?.data.success) {
                setLoader(false)
                if (flag === true) {
                    val = response.data.payload;
                } else {
                    setOptionData(response.data.payload);
                }
            } else {
                setOptionData([]);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
        if (flag === true) {
            return val
        }
    }
    const fieldMapping = {
        projectFrontEndMembers: 'projectFrontEndMembers',
        projectBackEndMembers: 'projectBackEndMembers',
        teamMembers: 'teamMembers',
        projectLead: 'projectLead'
    };

    const getSelectedValues = (fieldName: any, fieldValue: any) => {
        console.log(fieldName, '', fieldValue)
        switch (fieldName) {
            case fieldMapping.projectFrontEndMembers:
                return fieldValue.projectFrontEndMembers;
            case fieldMapping.projectBackEndMembers:
                return fieldValue.projectBackEndMembers;
            case fieldMapping.teamMembers:
                return fieldValue.teamMembers;
            case fieldMapping.projectLead:
                return [fieldValue.projectLead];
            case fieldValue.referral:
                return [fieldValue.referral]
            default:
                return [];
        }
    };



    const debounceTime = function <T>(fn: Function, delay: number) {
        let timer: NodeJS.Timeout;
        return function (this: T, ...args: any[]) {
            setLoader(true)
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        }
    }
    const getData = (d1: any, d2: any) => {
        searchApiCall(d1, false)
    }

    const onSearchData = debounceTime(getData, 1000);
    const handleInputChange = (e: any, newValue: any) => {
        if (e && newValue !== "") {
            onSearchData(newValue)
        } else {
            setOptionData([])
        }
    }

    let selectedValues: Option[];

    return (
        <div className="main">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='modal-heading'>
                    <h4>Candiadate Details</h4>
                    <CloseIcon className='modal-close-button' onClick={() => props.onClose()} />
                </div>
                <div className='modal-body'>
                    <div className="container-100">
                        <div className='leave-application-field-container'>
                            <div className='container-100'>
                                {
                                    field.map((item: fieldItem, index: number) => {
                                        return <React.Fragment key={index}>
                                            {
                                                item.type !== 'file' ? <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth key={index}>

                                                    <Controller
                                                        name={item.name}
                                                        control={control}
                                                        render={({ field }) => (

                                                            <TextField
                                                                {...field}
                                                                type={item.type}
                                                                required
                                                                id="outlined-basic"
                                                                variant="outlined"
                                                                label={item.label}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    // If item.name is 'contact', limit to 10 digits
                                                                    if (item.name === 'contactNo') {
                                                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                                                            handleField(e);
                                                                        }
                                                                    } else {
                                                                        handleField(e); // No restrictions for other fields
                                                                    }
                                                                }}
                                                                inputProps={item.name === 'contactNo' ? { maxLength: 10 } : {}}
                                                                value={fieldValue && fieldValue[item.name as keyof fieldValueProps]}
                                                            />

                                                        )}
                                                    />
                                                </FormControl> : <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth key={index}><FileUploader label='Upload Resume' required={true} onFileUpload={handleFileUpload} file={fieldValue?.resume} /></FormControl>

                                            }

                                        </React.Fragment>
                                    })
                                }
                                <div className='checkbox-container'>
                                    <FormControlLabel control={<Checkbox onChange={onChecked} checked={isReffered} />} label="Referral Details" />
                                </div>
                                {
                                    isReffered ?
                                        <>
                                            <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth >
                                                < label className='team-form-container-label'>Referral</label>
                                                {
                                                    <Autocomplete
                                                        limitTags={2}
                                                        id="checkboxes-tags-demo"
                                                        options={loader === true ? [{ name: 'Loading...' }] : optionData.filter((option) => {
                                                            selectedValues = getSelectedValues('referral', fieldValue);
                                                            return !selectedValues.some((selected: { name: any; }) => selected?.name === option.name);
                                                        })}
                                                        disableCloseOnSelect
                                                        disablePortal
                                                        getOptionLabel={(option) => option?.name || ''}
                                                        value={fieldValue && fieldValue['referral']}
                                                        onChange={(e, newValue) => handleAutocompleteChange(e, newValue, 'referral')}
                                                        onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props} key={option.name}>
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                required={!fieldValue?.referral?.name && !fieldValue?.referral?.employee_id}
                                                            />
                                                        )}
                                                    />
                                                }
                                            </FormControl>
                                            <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth >

                                                <Controller
                                                    name='referralComment'
                                                    control={control}
                                                    render={({ field }) => (

                                                        <TextField
                                                            {...field}
                                                            type='text'
                                                            required
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            label='How To Know'
                                                            onChange={handleReferalCommentField}
                                                            value={fieldValue && fieldValue['referral'].referralComment}
                                                        />

                                                    )}
                                                />
                                            </FormControl>
                                        </> : null
                                }
                            </div>

                        </div>
                        <div className='leave-application-button-container'>
                            <ButtonWrapper title='submit' id='submit-button' variant='contained' type='submit' />
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default AddCandidate